import { CONSOLIDATED_TODO_TO, CONSOLIDATED_CALENDAR_TODO_TO, CONSOLIDATED_TODO_BY, PRIVATE_TODO, IS_LOADING, IS_LOADED, ALL_TASKS, CONSOLIDATED_SUB_TODO_TO, CONSOLIDATED_SUB_TODO_BY, CONSOLIDATED_COMPLETED, CONSOLIDATED_SUB_COMPLETED, CONSOLIDATED_CAL_COMPLETED } from "./actions";

export const initialState = {
    consolidatedToDoTo: [],
    consolidatedCalendarToDoTo: [],
    consolidatedToDoBy: [],
    consolidatedSubToDoTo: [],
    consolidatedSubToDoBy: [],
    privateToDo: [],
    allTasks: [],
    consolidatedCompleted: [],
    consolidatedCalCompleted:[],
    consolidatedSubCompleted: []
};
export function consolidatedToDoReducer(state = initialState, action) {

    switch (action.type) {
        case CONSOLIDATED_TODO_TO:
            return {
                ...state,
                consolidatedToDoTo: action.payload
            };
        case CONSOLIDATED_CALENDAR_TODO_TO:
            return {
                ...state,
                consolidatedCalendarToDoTo: action.payload
            };
        case CONSOLIDATED_TODO_BY:
            return {
                ...state,
                consolidatedToDoBy: action.payload
            };
        case CONSOLIDATED_SUB_TODO_TO:
            return {
                ...state,
                consolidatedSubToDoTo: action.payload
            };
        case CONSOLIDATED_SUB_TODO_BY:
            return {
                ...state,
                consolidatedSubToDoBy: action.payload
            };
        case PRIVATE_TODO:
            return {
                ...state,
                privateToDo: action.payload
            };
        case ALL_TASKS:
            return {
                ...state,
                allTasks: action.payload
            };
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };
        case CONSOLIDATED_COMPLETED:
            return {
                ...state,
                consolidatedCompleted: action.payload
            };
        case CONSOLIDATED_CAL_COMPLETED:
            return {
                ...state,
                consolidatedCalCompleted: action.payload
            };
        case CONSOLIDATED_SUB_COMPLETED:
            return {
                ...state,
                consolidatedSubCompleted: action.payload
            };
        default:
            return state;
    }
}
