import { useDispatch, useSelector } from "react-redux"
import React, { useReducer, useEffect, useState, useCallback, useRef } from "react"
import { consolidatedToDoReducer, initialState } from "./reducer"
import { getActiveSprintAndActiveuserStory, getActiveSubUserStory, getActiveUserStory } from "./Services/getActiveUserStory";
import { getConsolidatedTodoBy, getConsolidatedTodoTo, getAllTasks, getConsolidatedSubTodoTo, getConsolidatedSubTodoBy, getConsolidatedCalendarToDoTo, getConsolidatedCompleted, getConsolidatedSubCompleted, getConsolidatedCalCompleted, getEventCalendarDash } from "./Services/getConsolidatedToDo";
import { getPrivateTodo } from "./Services/getPrivateToDo";
import { updateSetDone, updateUserStory } from "./Services/updateUserStory";
import $ from 'jquery';
import SideBar from "../SquadChat/sideNav"
import TopNavWithOutProject from "../Utility/TopNav/topnav"
import { getSubStringId } from "../../Common/SubStringConvert"
import MainTaskInfo from "../../Common/TasksModals/mainTaskInfo"
// import ModifyTemplate from "../../Common/TasksModals/modifyTemplate"
// import DeleteTemplate from "../../Common/TasksModals/deleteTemplate"
// import EditTemplate from "../../Common/TasksModals/editTemplate"
import AddPrivateToDo from "../../Common/PrivateToDo/addPrivateToDo"
import AddToToDoProjects from "../../Common/PrivateToDo/addToDoToProject"
import ModifyPrivateToDo from "../../Common/PrivateToDo/modifyPrivateToDo"
import DeletePrivateToDo from "../../Common/PrivateToDo/deletePrivateToDo"
import { getRoleCount, setRoleCount, setToken } from "../../Common/LocalStorage"
import { setCurrentUser } from "../../Common/Actions"
import API from "../../Common/Network/API"
import jwt_decode from "jwt-decode";
import { Redirect, Link } from "react-router-dom"
import moment from "moment"
import { useWebProperties } from "../../Common/webProperties";
import { moduleReducer, initialState as chatInitialState } from "../Modules/moduleReducer";
import MainTaskChatBox from "../../Common/ChatMainTask";
import ChatWorkflow from "../../Common/ChatWorkflow";
import ModifyMainTask from "../../Common/TasksModals/modifyMainTask";
import AddSubTask from "../../Common/SubTaskModals/addSubTask";
import MainTaskDelete from "../../Common/TasksModals/deleteMainTask";
import MainTaskVerify from "../../Common/TaskVerify/mainTaskVerify";
import AddToSprint from "../Backlogs/addToSprint";
import ChangeUserstoryStatus from "../Modules/changeUserstoryStatus";
import RoadBlock from "../../Common/RoadBlock";
// import { getAllTaskMessages } from "../Modules/network";
import { getAllUserStoryMessages } from "../Modules/Services/getAllUserStoryMessages";
import { Box } from "@material-ui/core";
import PropTypes from 'prop-types';
import './consolidateTodo.scss';
import { isMobile } from 'react-device-detect';
import DelegateTask from "../../Common/TasksModals/delegateTask";
import Alert from "../../Common/Alert";
import { MESSAGE_REFRESH_TIMER } from "../../Common/commonUsage";
import RootLoader from "../../Common/Loader/RootLoader";
import { addToSprintORKanban, addToSprintORKanbanSubTask } from "./Services/addToSprintORKanban";
import ModifySubTask from "../../Common/SubTaskModals/modifySubtask";
import MyTaskerCompleteReason from "./myTaskerCompleteReason";
import { FaChevronDown } from "react-icons/fa";
import { allTasks } from "./actions";
import TaskInfo from "../UserDashboard/taskInfo";

export default function ConsolidatedTodoList() {
    const getUser = useSelector(state => state.auth);
    const dispatch1 = useDispatch();
    const [state, dispatch] = useReducer(consolidatedToDoReducer, initialState)
    const [chatState, dispatch2] = useReducer(moduleReducer, chatInitialState)
    const [searchWord, setSearchWord] = useState('')
    const [preSearch, setpreSearch] = useState('');
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [value, setValue] = React.useState(0);
    const [switchTabs, setSwitchTabs] = React.useState(0);
    const [completeOpen, setCompleteOpen] = useState(false)
    const [loader, setLoader] = useState({
        loader: false,
        index: 0
    })
    const [filters, setFilters] = useState({});
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState(0);
    const [openPanel, setOpenPanel] = useState(null);
    const activities = useSelector(state => state.landingReducer.userActivities)

    const handleChange = (newValue) => {
        setFilters({});
        setSearchWord('')
        setpreSearch('')
        setValue(newValue);
        handleTabClick(newValue)
    };

    const filterData = (key, value) => {
        if (value) {
            setFilters({ [key]: value === 1 ? -1 : 1 })
        } else {
            setFilters({ [key]: 1 })
        }
    };

    const multipleSort = (a, b, dateFilter = false) => {
        if (filters.priority_level) {
            if (a.priority_level === "0" || a.priority_level === null) return 1;
            if (b.priority_level === "0" || b.priority_level === null) return -1;
            const aPriority = Number(a.priority_level);
            const bPriority = Number(b.priority_level);
            return filters.priority_level === -1 ? aPriority - bPriority : bPriority - aPriority;
        }
        if (filters.us_id) {
            return filters.us_id === 1 ? (a.us_id - b.us_id) : (b.us_id - a.us_id);
        }
        if (filters.assignedby) {
            let x = a.assignedby.toLowerCase();
            let y = b.assignedby.toLowerCase();
            if (filters.assignedby === 1) {
                return x < y ? -1 : x > y ? 1 : 0;
            }
            if (filters.assignedby === -1) {
                return x > y ? -1 : x < y ? 1 : 0;
            }
        }
        if (filters.assignedto) {
            let x = a.assignedto.toLowerCase();
            let y = b.assignedto.toLowerCase();
            if (filters.assignedto === 1) {
                return x > y ? -1 : x < y ? 1 : 0;
            }
            if (filters.assignedto === -1) {
                return x < y ? -1 : x > y ? 1 : 0;
            }
        }
        if (!filters && dateFilter) {
            // Secondary sort by created_date in descending order
            return new Date(b.created_date) - new Date(a.created_date);
        }
        // Push priority_level "0" and null to the bottom
        if (a.priority_level === "0" || a.priority_level === null) return 1;
        if (b.priority_level === "0" || b.priority_level === null) return -1;

        const aPriority = Number(a.priority_level);
        const bPriority = Number(b.priority_level);
        return (aPriority - bPriority) || 0;
    };

    const priorityColorCodes = ['#3DD896', '#FA8072', '#0BDA51', '#FFAA33', '#4682B4'];

    const [redirections, setRedirections] = useState({ action: null, redirect: false, status: null });
    const { ACTIVE_SPRINT, CONSOLIDATED_TODO, MAINTASKNAME, PRIVATE_TODO, PRIORITY_LEVEL, CHAT, ADD_TO_EPIC,
        MANAGEPROJECTS, PRODUCT_OWNER, SCRUM_MASTER, NEWSUBTASK, EDIT, ADD_TO_SPRINT, MAINTASK, VIEWSUBTASKS, KANBAN, role_array,
        AGILE_PROJECT_NAME, PROJECTNAME, ADD_PRIVATE_TODO, ACTION_ICON, MODULES, PENDING_SUBTASKS, ROADBLOCKS, KANBAN_NAME,
        TASKS_WARNING, ROADBLOCK_WARNING, WORKFLOW, WFT, ROADBLOCK, LIMITED_ACCESS_CONTRIBUTOR, WORKFLOW_ENABLE, SUBTASK, COMMENTS,APP_NAME } = useWebProperties();
    // const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    var date = moment();
    var currentDate = date.format('YYYY-MM-D');
    const pst = currentDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    useEffect(() => {
        getAllUserStoryMessages(dispatch2, getUser.user);
        getConsolidatedTodoTo(dispatch, getUser.user);
        getConsolidatedCalendarToDoTo(activities, dispatch, getUser.user, handleClose, TASKS_WARNING, ROADBLOCK_WARNING, MAINTASK);
        getEventCalendarDash(getUser.user,APP_NAME)
        getConsolidatedTodoBy(dispatch, getUser.user);
        getConsolidatedSubTodoTo(dispatch, getUser.user);
        getConsolidatedSubTodoBy(dispatch, getUser.user);
        getPrivateTodo(dispatch, getUser.user);
        getConsolidatedCompleted(dispatch, getUser.user);
        getConsolidatedCalCompleted(dispatch, getUser.user);
        getConsolidatedSubCompleted(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [value, switchTabs])

    useEffect(() => {
        const fetchUserStoryMessages = () => {
            getAllUserStoryMessages(dispatch2, getUser.user);
        };

        // Function to fetch messages and schedule the next fetch after 10 seconds
        const fetchDataAndScheduleNext = () => {
            fetchUserStoryMessages();
            setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
        };

        // Initial fetch when component mounts
        fetchUserStoryMessages();

        // Schedule the next fetch after 10 seconds
        const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

        // Clean up the timer on component unmount
        return () => clearTimeout(timerId);
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER || role_array[getUser.user.role] === LIMITED_ACCESS_CONTRIBUTOR) {
            setValue(1)
        } else {
            setValue(0)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.consolidatedToDoTo.length >= 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                })
            })
        }
    }, [state.consolidatedToDoTo])

    const debounceTimeoutRef = useRef(null);

    const handleSearch = useCallback((value) => {
        setSearchWord(value);

        // Clear the previous timeout if there is one
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout
        debounceTimeoutRef.current = setTimeout(() => {
            if (value.length > 0) {
                getAllTasks(dispatch, getUser.user, value);
            } else if (value.length === 0) {
                getConsolidatedTodoBy(dispatch, getUser.user);
                getConsolidatedSubTodoBy(dispatch, getUser.user);
                dispatch(allTasks([]))
            }
        }, 1000);
    }, [dispatch, getUser.user]);

    const SquadChangeStatus = async (project_id, project_name, sprintActiveStatus, action) => {
        try {
            const response = await API.post("squad_login.php", {
                username: getUser.user.empId,
                project_id: project_id,
                corp: project_name,
            }, {}, false);
            if (response.status === 'TRUE') {
                setToken('auth', response.jwt) //store the token information  with exp
                const tokenDetails = jwt_decode(response.jwt);
                setRoleCount('roleCount', tokenDetails.data.roleCount)
                dispatch1(setCurrentUser(tokenDetails.data)); //store the user information
                if (action !== null) {
                    setRedirections({ action, redirect: true, status: sprintActiveStatus })//checking for Active sprint or not to redirects to sprint board/backlogs board
                }
            }
        } catch (error) {
            // UpdateUserSquad(getUser.user.corp)
        }
        // dispatch(isLoaded());
    }

    const getMessagesCount = (data, msg, task, empId, followUp = null,) => {
        let task_id
        if (task.task_type === 'main_task') {
            task_id = task.us_id
        } else {
            task_id = `${task.main_task_id}-${task.t_id}`
        }
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        //for follow up check
        if (followUp === 'Follow Up') {
            return <div style={{ backgroundColor: 'teal', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Follow Up"
                onClick={(event) => handleChatOpen("maintask", task, msgCount, followUp)}>{followUp}</div>
        }

        return (
            <i>
                {msgCount.length > 0 ?
                    <div style={{ display: 'flex', marginRight: '-8px', width: '40px' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                        <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                    </div>
                    :
                    <div style={{ display: 'flex', marginRight: '-8px', width: '40px' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                    </div>}
            </i>
        )
    };

    const getOnlyCount = (data, msg, task, empId) => {
        let task_id
        if (task.task_type === 'main_task') {
            task_id = task.us_id
        } else {
            task_id = `${task.main_task_id}-${task.t_id}`
        }

        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return msgCount.length
    };

    const handleChatOpen = (action, index, sno, followUp = null) => {
        var info;
        if (index.task_type === 'sub_task') {
            setOpen({ status: true, action: 'workflow_task' });
            info = {
                action: 'workflow_task', id: index.main_task_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id, followUp: followUp, subtask_id: index.t_id, meetingUrl: index.meeting_url,taskTitle:index.task_title
            }
        } else {
            setOpen({ status: true, action: action });
            if (action === "maintask") {
                info = {
                    action: action, id: index.us_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                    story_type: index.story_type, us_id: index.us_id, followUp: followUp, group_task_type: index.group_task_type, meetingUrl: index.meeting_url
                }
            }
        }
        setCardInfo(info);
    }

    const handleChatClose = () => {
        setOpen({ status: false, index: 0 })
        getAllUserStoryMessages(dispatch2, getUser.user);
    };

    const handleOpen = (action, index, data) => {
        setpreSearch(searchWord);
        var info
        setOpen({ status: true, index: index, action: action });
        if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                main_task_id: index.main_task_id,
                view: view_status,
                us_id: index.us_id,
                projectName: index.epic_name, //epic/module name
                epicId: index.epic_id,
                moduleName: index.sprint_desc,
                moduleId: index.sprint_id,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                createdDate: index.created_date,
                taskProgress: index.story_status,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptance_criteria,
                assignedTo: index.assignedto,
                assignedBy: index.assignedby,
                completedDate: index.modified_date,
                completedStatus: index.complete_status,
                targetDate: index.target_date,
                device_id: index.device_id,
                project_name: index.project_name,
                story_type: index.story_type,
                task_type: index.task_type,
                startDate: index?.starttime,
                endDate: index?.endtime,
                calendarId: index?.calendar_id
            }
        }
        else if (action === "addToProject") {
            var status = "backlog_addUser"
            info = { view: status, data: data }
        }
        else if (action === "addUserStory") {
            info = { view: action, data: data }
        } else if (action === "modify") {
            status = "modify"
            info = { view: status, data: data }
        }
        else if (action === "delete") {
            status = "delete"
            info = { view: status, data: data }
        }
        else if (action === "complete") {
            status = "complete"
            info = { view: status, data: data }
        } else if (action === "private_todo_taskInfo") {
            view_status = "private_todo_taskInfo"
            info = {
                view: view_status,
                pt_id: index.pt_id,
                priority_level: index.priority_level,
                title: index.story_title,
                description: index.story_desc,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptance_criteria,
                assignedBy: index.username,
                personal_todo_status: index.personal_todo_status,
            }
        } else if (action === "addSubtask") {
            info = {
                mainTaskId: index.story_id,
                action: action, moduleId: index.sprint_id,
                ideaId: index.epic_id,
                assignedTo: index.assignedTo,
                us_id: index.us_id,
                title: index.story_title,
                projectName: index.project_name,
                template_story_id: index.template_story_id,
                is_workflow: index.is_workflow,
                project_id: index.project_id
            }
        }
        else if (action === "modifyTask" || action === 'delegate') {
            info = {
                moduleId: index.sprint_id,
                moduleName: index.sprint_desc,
                priorityLevel: index.priority_level,
                projectId: index.project_id,
                ideaId: index.epic_id,
                project_name: index.project_name,
                projectName:index.epic_name,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.task_type === 'sub_task' ? index.task_id : index.story_id,
                acceptanceCriteria: index.acceptance_criteria,
                storyPoints: index.story_points,
                userDetails: index.assignedto,
                id: index.assignedTo,
                targetDate: index.target_date,
                story_type: index.story_type,
                kanban_status: index.kanban_status,
                tasks: index,
                task_type: index.task_type,
                active_status: index.active_status,
                us_id: index.us_id,
                role: index.role,
                assignedBy: index.assigned_by
            }
        }
        else if (action === "modifySubTask") {
            info = {
                mainTaskId: index.story_id,
                subTaskId: index.task_id,
                taskTitle: index.story_title,
                taskDesc: index.story_desc,
                estimatedHours: index.estimatedHours,
                us_id: index.us_id,
                t_id: index.t_id,
                action: action,
                ideaId: '',
                acceptanceCriteria: index.acceptance_criteria,
                targetDate: index.target_date,
                userDetails: index.assignedto,
                id: index.assignedTo,
                priorityLevel: index.priority
            }
        }
        else if (action === "add") {
            status = "backlog_addUser"
            info = {
                view: status,
            }
        }
        else if (action === "changeUserstoryStatus") {
            info = {
                action: action,
                inprogress: data.inprogressStoryId,
                todo: data.todoStoryId,
                currentSprint: data.currentSprint,
                assignedTo: data.assignedTo,
                projectName: data.projectName,
                story_title: data.story_title,
                inprogress_story_title: data.inprogress_story_title,
                us_id: data.us_id,
                todo_us_id: data.todo_us_id,
                toDoProjectName: data.toDoProjectName,
                task_type: data.task_type,
                inprogress_task_type: data.inprogress_task_type,
                meeting_url: data.meetingUrl,
            }
        }
        else if (action === "add_to_epic") {
            var epic_status = "epic"
            info = {
                us_id: index.us_id,
                view: epic_status,
                projectid: index.project_id,
                taskId: index.story_id,
                project_id: index.project_id,
            }
        }
        else if (action === "current_add_to_sprint") {
            info = {
                project_id: index.project_id,
                us_id: index.us_id,
                projectid: index.project_id,
                taskId: index.story_id,
                // currentDate: data.currentDate,
                assignedTo: index.assignedto,
                assign_to: index.assignedTo,
                title: index.story_title,
                sprintDesc: index.sprint_desc,
                // device_id: index.device_id,
                // player_id: index.player_id,
                storyPoints: index.story_points,
            }
        }
        else if (action === "Delete") {
            info = {
                projectName: index.projectitle,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                moduleId: index.sprint_id,
                action: action
            }
        } else if (action === "movetobacklog") {
            let message = `${MAINTASK} is blocked`;
            info = {
                taskId: index.story_id,
                message: message,
                project_id: index.project_id,
                sprintId: index.sprint_id,
                kanban_status: index.kanban_status === "1" ? "1" : "0"
            }
        } else if (action === "commentInfo") {
            info = {
                title: index.title,
                description: index.description,
                us_id: index.us_id,
                id: index.id,
                mainTaskId: index.mainTaskId,
                mainTaskTitle: index.mainTaskTitle,
                dependencyId: index.dependencyId,
                status: index.complete_status,
                modifiedby: index.modifiedby,
                timeLeft: index.timeLeft,
                extraHours: index.extraHours,
                taskDesc: index.taskDesc,
                ideaTitle: index.epic_name,
                laneId: index.laneId,
                moduleDesc: index.moduleDesc,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptanceCriteria,
                assignedDate: index.assignedDate,
                targetDate: index.targetDate,
                modifiedDate: index.modifiedDate,
                action: action,
                openStatus: true,
                task_end_date: index.task_end_date,
                assignedto: index.assignedto,
                assignedby: index.assignedby,
                road_block_ids: index.road_block_ids,
                combine_tasks: index.combine_tasks,
                blocking_id: index.blocking_id,
                from_device: index.from_device,
                task_count: index.task_count,
                sub_tasks: index.sub_tasks
            }
        }
        else if (action === "taskCommentInfo") {
            info = {
                title: index.title,
                description: index.description,
                us_id: index.us_id,
                id: index.id,
                mainTaskId: index.mainTaskId,
                mainTaskTitle: index.mainTaskTitle,
                dependencyId: index.dependencyId,
                status: index.complete_status,
                modifiedby: index.modifiedby,
                timeLeft: index.timeLeft,
                extraHours: index.extraHours,
                taskDesc: index.taskDesc,
                ideaTitle: index.epic_name,
                laneId: index.laneId,
                moduleDesc: index.moduleDesc,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptanceCriteria,
                assignedDate: index.assignedDate,
                targetDate: index.targetDate,
                modifiedDate: index.modifiedDate,
                action: action,
                openStatus: true,
                task_end_date: index.task_end_date,
                assignedto: index.assignedto,
                assignedby: index.assignedby,
                road_block_ids: index.road_block_ids,
                combine_tasks: index.combine_tasks,
                blocking_id: index.blocking_id,
                from_device: index.from_device,
                task_count: index.task_count,
                sub_tasks: index.sub_tasks
            }
        }

        setCardInfo(info)
    };

    const handleClose = () => {
        const fetchLoad = async () => {
            setOpen({ status: false, index: 0 })
            setCompleteOpen(false)
            await getConsolidatedTodoTo(dispatch, getUser.user);
            await getConsolidatedCalendarToDoTo(activities, dispatch, getUser.user, handleClose, TASKS_WARNING, ROADBLOCK_WARNING, MAINTASK);
            await getEventCalendarDash(getUser,APP_NAME)
            await getConsolidatedTodoBy(dispatch, getUser.user);
            await getPrivateTodo(dispatch, getUser.user);
            await getConsolidatedSubTodoTo(dispatch, getUser.user);
            await getConsolidatedCompleted(dispatch, getUser.user);
            await getConsolidatedCalCompleted(dispatch, getUser.user);
            await getConsolidatedSubCompleted(dispatch, getUser.user);
            handleSearch(preSearch);
            setLoader({ loader: false, index: 0 })
        }
        fetchLoad()
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 })
        setCompleteOpen(false)
    };

    const handleSwitchTabs = async (tabIndex) => {
        setSearchWord('')
        setOpenPanel(null)
        await setSwitchTabs(tabIndex);
    };

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const { action, redirect, status } = redirections;
    if (redirect) {
        if ((getRoleCount('roleCount') >= 1) && (action === "sprints" || action === "project")) {
            return status === ACTIVE_SPRINT ? <Redirect to={`/${MODULES}`} />
                : status === KANBAN ? <Redirect to={`/${KANBAN_NAME}`} /> : <Redirect to={`/${PENDING_SUBTASKS}`} />
        } else if ((getRoleCount('roleCount') >= 1) && action === "epic") {
            return <Redirect to={`/user/${MANAGEPROJECTS}`} />
        } else if ((getRoleCount('roleCount') >= 1)) {
            return <Redirect to={`/${MODULES}`} />
        } else {
            return <Redirect to='/' />
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box pt={1}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const BlockedTask = `This ${MAINTASK} is ${ROADBLOCK} you can not start it`;
    const BlockedDelegate = `This ${MAINTASK} is ${ROADBLOCK} you can not delegate it`;

    const now = new Date(); // Current time
    const thirtyMinutesLater = new Date(now.getTime() + 30 * 60 * 1000)
    const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000)
    const todayStart = new Date(now.setHours(0, 0, 0, 0));
    const todayEnd = new Date(now.setHours(23, 59, 59, 999));
    const startOfWeek = new Date(now); 
    startOfWeek.setDate(now.getDate() - now.getDay() + 1);
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(now);
    endOfWeek.setDate(now.getDate() - now.getDay() + 7);
    endOfWeek.setHours(23, 59, 59, 999);
    // const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1));
    // const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 7));
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);

    var activeCalendarTasks = state.consolidatedCalendarToDoTo?.filter((val) => {
        // return val
        if (searchWord === "" && (val.calendar_id !== null && val.calendar_id !== undefined)) {
            if (new Date(val.endtime) >= new Date()) {
                return val
            }
        } else if ((`${getSubStringId(val?.project_name, 3)}-${val?.us_id}-${val?.story_title}`.toLowerCase()?.includes(searchWord.toLowerCase())) || (`${val?.main_task_id}-${val?.us_id}-${val?.story_title}`.toLowerCase()?.includes(searchWord.toLowerCase()))) {
            if (val.calendar_id !== null && val.calendar_id !== undefined) {
                if (new Date(val.endtime) >= new Date()) {
                    return val
                }
            }
        }
        return false
    })

    //filterCalendarTasks(activeCalendarTasks)
    var completedMeetings=state.consolidatedCalCompleted.filter((val)=>{
        const meetingEnd=new Date(val.target_date)
        const meetingEnding=new Date(meetingEnd.setHours(0, 0, 0, 0))
        if (todayStart <= meetingEnding && todayEnd >= meetingEnding) {
            return val;
        }
        return false
    })

    var todayMeetings = activeCalendarTasks?.filter((val) => {
        if (todayStart <= new Date(val.starttime) && todayEnd >= new Date(val.starttime)) {
            return val;
        }
        return false
    })
    var weekMeetings = activeCalendarTasks?.filter((val) => {
        if (startOfWeek <= new Date(val.starttime) && endOfWeek >= new Date(val.starttime)) {
            return val;
        }
        return false
    })
    var monthMeetings = activeCalendarTasks?.filter((val) => {
        if (startOfMonth <= new Date(val.starttime) && endOfMonth >= new Date(val.starttime)) {
            return val;
        }
        return false
    })
    var yearMeetings = activeCalendarTasks?.filter((val) => {
        if (startOfYear <= new Date(val.starttime) && endOfYear >= new Date(val.starttime)) {
            return val;
        }
        return false
    })
    var exceptYearMeetings = activeCalendarTasks?.filter((val) => {
        if (endOfYear < new Date(val.starttime)) {
            return val;
        }
        return false
    })

    const handleToggle = (panel) => {
        if(openPanel === panel) {
            setOpenPanel(null);
        } else {
            setOpenPanel(panel);
        }
    };

    const getRotation = (panel) => openPanel === panel ? 'rotate(180deg)' : 'rotate(0deg)';


    function renderData(array) {
        return <>
            <table className="tbl">
                <tbody>
                    {!isMobile ? <tr>
                        <td style={{ border: '1.8px solid #3435364d', backgroundColor: 'rgb(244, 250, 247)' }}>
                            <div class={"d-flex col-12"}>
                                <div style={{ width: switchTabs === 2 ? "330px" : switchTabs === 0 && value === 0 ? '66%' : '75%', textAlign: "center", cursor: 'pointer' }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('us_id', filters.us_id)}>
                                        Task Details&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div>
                                <div style={{ width: "55px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Chat</p>
                                </div>
                                {(switchTabs !== 2) && (<div style={{ width: "55px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Activity Log</p>
                                </div>)}
                                {switchTabs === 0 && value === 0 ? <div style={{ width: "95px", textAlign: "center", cursor: 'pointer', }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('assignedby', filters.assignedby)}>
                                        Assigned by&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div> : null}
                                {switchTabs === 0 && value === 1 ? <div style={{ width: "95px", textAlign: "center", cursor: 'pointer' }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('assignedto', filters.assignedto)}>
                                        Assigned to&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div> : null}
                                {switchTabs === 0 && value === 0 ? <div style={{ width: "90px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Action</p>
                                </div> : null}
                                {switchTabs === 0 && value === 0 ? <div style={{ width: "90px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Delegate to</p>
                                </div> : null}
                                {switchTabs === 0 && value === 1 ? <div style={{ width: "85px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Action</p>
                                </div> : null}
                                {switchTabs === 0 ? <div style={{ width: "75px", textAlign: "center", cursor: 'pointer' }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('priority_level', filters.priority_level)}>
                                        Priority&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div> : null}
                                {switchTabs === 0 ? <div style={{ width: "60px", textAlign: "start" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>More</p>
                                </div> : null}
                                {switchTabs === 2 ? <div style={{ width: "80px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Assigned by</p>
                                </div> : null}
                                {switchTabs === 2 ? <div style={{ width: "190px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Action</p>
                                </div> : null}
                                {switchTabs === 2 ? <div style={{ width: "100px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Meeting Date</p>
                                </div> : null}
                                {switchTabs === 2 ? <div style={{ width: "100px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Meeting Duration</p>
                                </div> : null}
                            </div>
                        </td>
                    </tr> : null}
                    {/* eslint-disable-next-line */}
                    {array.length > 0 ? array.filter((val) => {
                        // return val
                        if (searchWord === "" && (val.road_block_ids === null || val?.road_block_ids === undefined)) {
                            return val
                        } else if ((`${getSubStringId(val.project_name, 3)}-${val.us_id}-${val.story_title}`.toLowerCase().includes(searchWord.toLowerCase()) && (val.road_block_ids === null || val?.road_block_ids === undefined)) || (val?.road_block_ids === null && val.blocked_id !== null && val.userStoryId !== null && val.userStoryId.includes(searchWord) && (val.road_block_ids === null || val?.road_block_ids === undefined)) || (`${val.main_task_id}-${val.us_id}-${val.story_title}`.toLowerCase().includes(searchWord.toLowerCase()) && (val.road_block_ids === null || val?.road_block_ids === undefined))) {
                            return val
                        }
                    }).sort((a, b) => {
                        // Primary sort by active_status ('1' should come before other statuses)
                        if (a.active_status === '1' && b.active_status !== '1') {
                            return -1;
                        } else if (a.active_status !== '1' && b.active_status === '1') {
                            return 1;
                        }
                        // Secondary sort by created_date in descending order
                        //return new Date(b.created_date) - new Date(a.created_date);

                        const aStartTime = a.starttime ? new Date(a.starttime) : null;
                        const bStartTime = b.starttime ? new Date(b.starttime) : null;

                        const isAWithin30Minutes = aStartTime && aStartTime <= thirtyMinutesLater;
                        const isBWithin30Minutes = bStartTime && bStartTime <= thirtyMinutesLater;

                        if (isAWithin30Minutes && !isBWithin30Minutes) {
                            return -1;
                        } else if (!isAWithin30Minutes && isBWithin30Minutes) {
                            return 1;
                        }

                        if (aStartTime && bStartTime) {
                            return aStartTime - bStartTime;
                        }

                        return multipleSort(a, b);
                        // return 0;

                    }).map((todo, index) => {
                        const date = new Date();
                        const startDate = new Date(todo?.starttime)
                        const endDate = new Date(todo?.endtime)
                        let currentDay = String(date.getDate()).padStart(2, '0');

                        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

                        let currentYear = date.getFullYear();

                        // we will display the date as DD-MM-YYYY 

                        let currentDate1 = `${currentYear}-${currentMonth}-${currentDay}`;
                        let current_date = new Date(currentDate1);
                        const { blocking_id, assignedby, sprint_desc, assignedto, main_task_id, target_date, complete_status, project_name, sprint_target_date, us_id, story_title, project_id, active_status, priority_level, epic_name, story_id, sprint_id, assignedTo, kanban_status, role, template_story_id, status, is_workflow, task_count, story_type, userStoryId, blocked_id, task_type, task_id, priority, calendar_id, meeting_url, group_task_type } = todo;
                        let sprintActiveStatus = kanban_status === "1" && sprint_target_date === "0001-01-01" ? KANBAN : new Date(sprint_target_date) >= new Date(pst) ? ACTIVE_SPRINT : PENDING_SUBTASKS;
                        let badgeColor = kanban_status === "1" && sprint_target_date === "0001-01-01" ? '#ADD8E6' : sprintActiveStatus === ACTIVE_SPRINT ? '#8F00FF' : '#E37066';
                        const accessRole = (role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER);//project/user story wise user role
                        let SPRINT_OR_KANBAN_WARNING = `You can't start this Task because of not having ${ACTIVE_SPRINT} Board`;

                        return (
                            <tr key={index}>
                                <td style={{ border: '1.8px solid #3435364d' }}>
                                    <div className="col-12 td1"
                                        style={
                                            (current_date > new Date(target_date) && target_date && complete_status === 'pending' && target_date !== '0000-00-00') ?
                                                { backgroundColor: '#f58484' } : null}>
                                        <div>
                                            <div class={isMobile ? "col-12" : "d-flex col-12"}>
                                                {
                                                    isMobile &&
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ padding: '5px', marginRight: '10px' }}>
                                                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                    {
                                                                        getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                    }
                                                                </button>
                                                            </div>
                                                            <div className="dropdown show" style={{ cursor: 'pointer' }}>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                {(calendar_id === null || calendar_id === undefined) ? <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0', marginLeft: '10px' }} />
                                                                </a> : null}
                                                                {
                                                                    status !== 'completed' ?
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                            <div>
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                                {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name?.substring(0, 15)}</button>}
                                                                                {(accessRole && epic_name === null) && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                                {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                                {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                                {
                                                                                    story_type === 'group' && main_task_id === '0' ?
                                                                                        blocked_id === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>
                                                                                        :
                                                                                        active_status !== '-1' && story_type !== 'group' && (accessRole || kanban_status === "1") && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => {
                                                                                            if (task_type === 'sub_task') {
                                                                                                handleOpen("modifySubTask", todo)
                                                                                            } else {
                                                                                                handleOpen("modifyTask", todo)
                                                                                            }
                                                                                        }}>{EDIT}</button>
                                                                                }
                                                                                {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                                {
                                                                                    WORKFLOW_ENABLE === '1' ?
                                                                                        task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")} ><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, sprint_desc: sprint_desc, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, subTasksAssignedBy: assignedby, targetDate: sprint_target_date, us_id: us_id, moduleName: epic_name, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group tasks</Link></button>}
                                                                                {
                                                                                    WORKFLOW_ENABLE === '1' ?
                                                                                        task_type === 'main_task' && story_type !== 'group' ? <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button> : null
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                                {
                                                                    status === 'completed' ?
                                                                        <del>
                                                                            <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>

                                                                        </del>
                                                                        :
                                                                        <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                                }      {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                                {(story_type === 'group') && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={'Group Task'}>{'GR'}</div>}
                                                                <div>{task_type !== 'sub_task' && blocking_id !== false ?
                                                                    blocking_id[0].us_id !== null &&
                                                                    <p style={{ fontWeight: 'bold', cursor: 'pointer', backgroundColor: 'red', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" >{getSubStringId(project_name, 3)}{'-'}{blocking_id[0].us_id}</p> :
                                                                    null}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{
                                                    width: switchTabs === 2 ? "325px" : '100%',
                                                    alignItems: 'center', display: isMobile ? "none" : 'flex'
                                                }}>
                                                    {
                                                        status === 'completed' ?
                                                            <del>
                                                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>

                                                            </del>
                                                            :
                                                            <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                    }
                                                    {/* <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{task_type === 'sub_task' ? `${main_task_id}-${userStoryId}` : userStoryId}</span>}</p> </b> */}
                                                    {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                    {story_type === 'group' && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={group_task_type !== null ? `Group Task  ${group_task_type} Access` : null}>{"GR"} {group_task_type !== null ? `${group_task_type?.substring(0, 1)}A` : null}</div>}
                                                    <div>{task_type !== 'sub_task' && blocking_id !== false ?
                                                        blocking_id[0].us_id !== null &&
                                                        <p style={{ fontWeight: 'bold', cursor: 'pointer', backgroundColor: 'red', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" >{getSubStringId(project_name, 3)}{'-'}{blocking_id[0].us_id}</p> :
                                                        null}</div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ padding: '5px', marginRight: '5px', marginTop: '2px', float: "right", display: isMobile ? "none" : {} }}>
                                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                            {
                                                                getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId, task_type)
                                                            }
                                                        </button>
                                                    </div>
                                                    {(switchTabs !== 2 || !todo.calendar_id) && (<div style={{ padding: '5px', marginRight: '5px', float: "right", display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
                                                        <button
                                                            style={{
                                                                border: "0",
                                                                width: "8px",
                                                                height: "20px",
                                                                backgroundColor: "transparent",
                                                            }}
                                                            type="button"
                                                            onClick={() =>
                                                                handleOpen(
                                                                    task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                                                    {
                                                                        title: todo.title,
                                                                        subTaskDesc: todo.subTaskDesc,
                                                                        us_id: todo.us_id,
                                                                        id: todo.id,
                                                                        main_task_id: todo.main_task_id,
                                                                        dependencyId: todo.dependencyId,
                                                                        timeLeft: todo.timeLeft,
                                                                        complete_status: todo.complete_status,
                                                                        modifiedby: todo.modifiedby,
                                                                        extraHours: todo.extraHours,
                                                                        mainTaskTitle: todo.mainTaskTitle,
                                                                        taskDesc: todo.taskDesc,
                                                                        epic_name: todo.epic_name,
                                                                        laneId: todo.laneId,
                                                                        moduleDesc: todo.moduleDesc,
                                                                        story_points: todo.story_points,
                                                                        acceptanceCriteria: todo.acceptanceCriteria,
                                                                        assignedDate: todo.assignedDate,
                                                                        targetDate: todo.targetDate,
                                                                        modifiedDate: todo.modifiedDate,
                                                                        action: task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                                                        task_end_date: todo.task_end_date,
                                                                        road_block_ids: todo.road_block_ids,
                                                                        combine_tasks: todo.combine_tasks,
                                                                        blocking_id: todo.blocking_id,
                                                                        from_device: todo.from_device
                                                                    })}
                                                        >
                                                            <img
                                                                src="images/common/comments.svg"
                                                                alt="logo"
                                                                title={COMMENTS}
                                                                style={{ width: "20px", height: "25px", marginLeft: "-10px", marginTop: "-10px" }}
                                                            />
                                                        </button>
                                                    </div>)}
                                                    {
                                                        value === 2 &&
                                                        <div style={{ backgroundColor: '#000080', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '85px', textAlign: 'center', height: 'fit-content' }} title={`Assigned By ${assignedby}`}>{getSubStringId(assignedby, 3)}23</div>

                                                    }
                                                    <div style={{ backgroundColor: value === 0 ? '#000080' : '#019BE1', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '85px', textAlign: 'center', height: 'fit-content', padding: "3px" }} title={value === 0 ? `Assigned By: ${assignedby}` : `Assigned To ${assignedto}`}>{getSubStringId(value === 0 ? assignedby : assignedto, 3)}</div>
                                                    {(active_status === '0' && value === 0 && (calendar_id === null || calendar_id === undefined)) ?
                                                        <button disabled={loader.loader && loader.index === index} className="btn btn-sm" style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {
                                                            setLoader({ loader: true, index: index })
                                                            if (task_type === 'sub_task') {
                                                                getActiveSubUserStory(getUser.user, task_id, sprint_id, handleClose, handleOpen, `${SUBTASK} in progress`, project_name, main_task_id, story_id, project_id, us_id, priority)
                                                            } else {
                                                                getActiveUserStory(activities, getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name)
                                                            }
                                                        }}>
                                                            Start
                                                        </button> : null}
                                                        {(active_status === '0' && value === 0 && (calendar_id !== null && calendar_id !== undefined)) && complete_status === 'pending'?
                                                        <button disabled={(loader.loader && loader.index === index) || !(fiveMinutesLater > new Date(startDate))} className="btn btn-sm text-center" style={{ backgroundColor: (fiveMinutesLater > new Date(startDate)) ? "teal" : 'gray', color: 'white', marginLeft: 70,marginRight:60, marginTop: 5, marginBottom: 5, textAlign: 'center', height: 'fit-content', cursor: (fiveMinutesLater > new Date(startDate)) ? 'pointer' : '', opacity: (fiveMinutesLater > new Date(startDate)) ? '' : '0.50' }} onClick={() => {
                                                            setLoader({ loader: true, index: index })
                                                            if (task_type === 'sub_task' && (sprint_id !== null && sprint_id !== undefined)) {
                                                                getActiveSubUserStory(getUser.user, task_id, sprint_id, handleClose, handleOpen, `${SUBTASK} in progress`, project_name, main_task_id, story_id, project_id, us_id, priority)
                                                            } else if (sprint_id !== null && sprint_id !== undefined) {
                                                                getActiveUserStory(activities, getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, meeting_url)
                                                            } else {
                                                                getActiveSprintAndActiveuserStory(getUser.user, story_type, main_task_id, target_date, story_id, assignedTo, story_title, us_id, project_id, project_name, meeting_url, handleClose, handleOpen, `${MAINTASK} in progress`, activities)
                                                            }
                                                        }}>
                                                            Join
                                                        </button> : null}
                                                        {(active_status === '0' && value === 0 && (calendar_id !== null && calendar_id !== undefined)) && complete_status === 'completed'?
                                                        <button disabled={(loader.loader && loader.index === index) || !(fiveMinutesLater > new Date(startDate))} className="btn btn-sm text-center" style={{ backgroundColor: 'red', color: 'white', marginLeft: 70,marginRight:10, marginTop: 5, marginBottom: 5, textAlign: 'center', height: 'fit-content', cursor: (fiveMinutesLater > new Date(startDate)) ? 'pointer' : '', opacity: (fiveMinutesLater > new Date(startDate)) ? '' : '0.50' }} onClick={() => {
                                                            Alert('waring',"This task is already completed")
                                                        }}>
                                                            Completed
                                                        </button> : null}                                                                                                                                                    
                                                        <div className="d-flex flex-row">                                                            
                                                        {(active_status === '1' && value === 0) ?
                                                            <button disabled={loader.loader && loader.index === index} className="btn btn-sm" style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: (calendar_id !== null && calendar_id !== undefined) ? '86px' : '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {
                                                                setLoader({ loader: true, index: index })
                                                                let info = { task_id: task_id, story_id: story_id, task_type: task_type, project_name: project_name, main_task_id: main_task_id, story_type: story_type, us_id: us_id, story_title: story_title, blocked_id: blocked_id, userStoryId: userStoryId, assignedTo: assignedTo };
                                                                setCardInfo(info);
                                                                setCompleteOpen(true);
                                                                // if (task_type === 'sub_task') {
                                                                //     updateSetDone(getUser.user, task_id, story_id, handleClose, `${SUBTASK} is completed`)
                                                                // } else {
                                                                //     updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)

                                                                // }
                                                            }}>
                                                                Inprogress
                                                            </button> : null}
                                                        {(active_status === '1' && value === 0 && (calendar_id !== null && calendar_id !== undefined)) ?
                                                            <button disabled={loader.loader && loader.index === index} className="btn btn-sm" style={{ backgroundColor: (fiveMinutesLater > new Date(startDate)) ? "teal" : 'gray', color: 'white', marginLeft: 2, width: 90, marginTop: 5, marginBottom: 5, textAlign: 'center', height: 'fit-content', cursor: (fiveMinutesLater > new Date(startDate)) ? 'pointer' : '', opacity: (fiveMinutesLater > new Date(startDate)) ? '' : '0.50' }} onClick={() => {
                                                                if (meeting_url !== null && meeting_url !== undefined) {
                                                                    window.open(meeting_url, '_blank');
                                                                }
                                                            }}>
                                                                Re Join
                                                            </button> : null}
                                                    </div>

                                                    {((sprintActiveStatus === PENDING_SUBTASKS && active_status === '-1') && value === 0 && (calendar_id === null || calendar_id === undefined)) ?
                                                        <button className="btn btn-sm" disabled={loader.loader && loader.index === index} style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {
                                                            setLoader({ loader: true, index: index })
                                                            if (task_type === 'sub_task') {
                                                                Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanbanSubTask(getUser.user, task_id, sprint_id, handleClose, handleOpen, `${SUBTASK} in progress`, project_name, main_task_id, story_id, project_id, us_id, priority, SPRINT_OR_KANBAN_WARNING))

                                                            } else {
                                                                Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanban(activities, getUser.user, story_id, MAINTASK, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, project_id, target_date, SPRINT_OR_KANBAN_WARNING))
                                                            }
                                                        }}>
                                                            Start
                                                        </button> : null}
                                                    {(calendar_id !== null && calendar_id !== undefined && switchTabs === 2) ?
                                                        <button className="btn btn-sm" style={{ backgroundColor: 'transparent', color: (new Date() > new Date(startDate)) && !(active_status === '1') ? 'red' : 'black', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '100px', padding: '5px', textAlign: 'center', height: 'fit-content', fontWeight: '600' }}>
                                                            {`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`}
                                                        </button> : null}
                                                    {(calendar_id !== null && calendar_id !== undefined && switchTabs === 2) ?
                                                        <button className="btn btn-sm" style={{ backgroundColor: 'transparent', color: (new Date() > new Date(startDate)) && !(active_status === '1') ? 'red' : 'black', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '100px', padding: '5px', textAlign: 'center', height: 'fit-content', fontWeight: '600' }}>
                                                            {`${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')} to ${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`}
                                                        </button> : null}
                                                    {value === 1 || value === 2 ? getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId, 'Follow Up')
                                                        : (calendar_id === null || calendar_id === undefined) ? <button className="btn btn-sm" style={{ backgroundColor: '#7290ce', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Delegate" onClick={() => Alert('warning', active_status === '-1' ? BlockedDelegate : handleOpen("delegate", todo))}>Delegate</button> : null}
                                                    {/* <div style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '155px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</div> */}
                                                    {/* Don`t remove these below lines which were related to Epic and Project Names and redirections` */}
                                                    {/* <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</div>
                                                <div style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</div> */}
                                                    <div style={{ backgroundColor: priorityColorCodes[priority_level] ? priorityColorCodes[priority_level] : '#4682B4', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '65px', textAlign: 'center', height: 'fit-content' }} title={priority_level ? PRIORITY_LEVEL : null}> {priority_level && (calendar_id === null || calendar_id === undefined) ? `P:${priority_level}` : null}</div>
                                                    {value === 1 || value === 2 ? isMobile ? <div style={{ width: '80px' }}></div> : null : null}

                                                    {/* <div style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{story_points}</div> */}
                                                    <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right", display: isMobile ? "none" : {} }}>
                                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                        {(calendar_id === null || calendar_id === undefined) ? <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                            <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                        </a> : null}
                                                        {
                                                            status !== 'completed' ?
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                    <div>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                        {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name?.substring(0, 15)}</button>}
                                                                        {accessRole && epic_name === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                        {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                        {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                        {
                                                                            story_type === 'group' && main_task_id === '0' ?
                                                                                blocked_id === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>
                                                                                :
                                                                                active_status !== '-1' && story_type !== 'group' && (accessRole || kanban_status === '1') && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => {
                                                                                    if (task_type === 'sub_task') {
                                                                                        handleOpen("modifySubTask", todo)
                                                                                    } else {
                                                                                        handleOpen("modifyTask", todo)
                                                                                    }
                                                                                }}>{EDIT}</button>
                                                                        }
                                                                        {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                        {
                                                                            WORKFLOW_ENABLE === '1' ?
                                                                                task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")} ><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}> <Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}
                                                                        {
                                                                            WORKFLOW_ENABLE === '1' ?
                                                                                task_type === 'main_task' && story_type !== 'group' ? <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button> : null
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table >
        </>
    }

    function renderAllData(array) {
        return <>
            <table className="tbl arc">
                <tbody>
                    {!isMobile ? <tr>
                        <td style={{ border: '1.8px solid #3435364d', backgroundColor: 'rgb(244, 250, 247)' }}>
                            <div class={"d-flex col-12"}>
                                <div style={{ width: '72%', textAlign: "center", cursor: "pointer" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('us_id', filters.us_id)}>
                                        Task Details&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div>
                                <div style={{ width: "55px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Chat</p>
                                </div>
                                <div style={{ width: "55px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Activity Log</p>
                                </div>
                                <div style={{ width: "95px", textAlign: "center", cursor: "pointer" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('assignedby', filters.assignedby)}>
                                        Assigned by&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div>
                                <div style={{ width: "75px", textAlign: "center" }}>
                                    <p style={{ fontSize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }}>Action</p>
                                </div>
                                <div style={{ width: "70px", textAlign: "center", cursor: "pointer" }}>
                                    <p style={{ fontSconsize: '12px', fontFamily: 'Open Sans,sans-serif', fontWeight: "bold", paddingTop: '5px', paddingBottom: '5px' }} onClick={() => filterData('priority_level', filters.priority_level)}>
                                        Priority&nbsp;&nbsp;
                                        <img src="images/common/sort_icon.png" style={{ width: '12px', height: '12px' }} alt="" /></p>
                                </div>

                            </div>
                        </td>
                    </tr> : null}
                    {/* eslint-disable-next-line */}
                    {array.length > 0 ? array?.filter((val) => {
                        // return val
                        if (searchWord === "" && (val.road_block_ids === null || val?.road_block_ids === undefined) && (val.calendar_id === null || val.calendar_id === undefined)) {
                            return val
                        } else if ((`${getSubStringId(val.project_name, 3)}-${val.us_id}-${val.story_title}`.toLowerCase().includes(searchWord.toLowerCase()) && (val.road_block_ids === null || val?.road_block_ids === undefined)) || (val?.road_block_ids === null && val.blocked_id !== null && val.userStoryId !== null && val.userStoryId.includes(searchWord) && (val.road_block_ids === null || val?.road_block_ids === undefined)) || (`${val.main_task_id}-${val.us_id}-${val.story_title}`.toLowerCase().includes(searchWord.toLowerCase()) && (val.road_block_ids === null || val?.road_block_ids === undefined))) {
                            return val
                        }
                    })
                        .sort((a, b) => {
                            // Primary sort by active_status ('1' should come before other statuses)
                            if (a.active_status === '1' && b.active_status !== '1') {
                                return -1;
                            } else if (a.active_status !== '1' && b.active_status === '1') {
                                return 1;
                            }
                            // Secondary sort by created_date in descending order
                            // if(new Date(b.created_date) && new Date(a.created_date)) {
                            // return new Date(b.created_date) > new Date(a.created_date); //added via 
                            return multipleSort(a, b, true);
                        }).map((todo, index) => {
                            const { blocking_id, assignedby, assignedto, main_task_id, target_date, currentDate, complete_status, project_name, sprint_target_date, us_id, story_title, project_id, active_status, priority_level, epic_name, story_id, sprint_id, assignedTo, kanban_status, role, template_story_id, status, is_workflow, task_count, story_type, userStoryId, blocked_id, task_type, task_id, priority, group_task_type } = todo;
                            let sprintActiveStatus = kanban_status === "1" && sprint_target_date === "0001-01-01" ? KANBAN : new Date(sprint_target_date) >= new Date(pst) ? ACTIVE_SPRINT : PENDING_SUBTASKS;
                            let badgeColor = kanban_status === "1" && sprint_target_date === "0001-01-01" ? '#ADD8E6' : sprintActiveStatus === ACTIVE_SPRINT ? '#8F00FF' : '#E37066';
                            const accessRole = (role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER);//project/user story wise user role
                            let SPRINT_OR_KANBAN_WARNING = `You can't start this Task because of not having ${ACTIVE_SPRINT} Board`;

                            return (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #e1e1e1' }}>
                                        <div className="col-12 td1" style={(currentDate > sprint_target_date && complete_status === 'pending' && target_date !== '0000-00-00') ? { backgroundColor: '#f58484' } : null}>
                                            <div>
                                                <div class={isMobile ? "col-12" : "d-flex col-12"}>
                                                    {
                                                        isMobile &&
                                                        <>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ padding: '5px', marginRight: '10px' }}>
                                                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                        {
                                                                            getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                        }
                                                                    </button>
                                                                </div>
                                                                {
                                                                    status !== 'completed' ?
                                                                        <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right", display: isMobile ? "none" : {} }}>
                                                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                                            </a>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                                <div>
                                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                                    {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                                    {accessRole && epic_name === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                                    {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                                    {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                                    {
                                                                                        story_type === 'group' && main_task_id === '0' ?
                                                                                            blocked_id === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>
                                                                                            :
                                                                                            active_status !== '-1' && story_type !== 'group' && (accessRole || kanban_status === '1') && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => {
                                                                                                if (task_type === 'sub_task') {
                                                                                                    handleOpen("modifySubTask", todo)
                                                                                                } else {
                                                                                                    handleOpen("modifyTask", todo)
                                                                                                }
                                                                                            }}>{EDIT}</button>
                                                                                    }
                                                                                    {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                                    {
                                                                                        WORKFLOW_ENABLE === '1' ?
                                                                                            task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}
                                                                                    {
                                                                                        WORKFLOW_ENABLE === '1' ?
                                                                                            task_type === 'main_task' && story_type !== 'group' ? <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button> : null
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                        </div>
                                                                }
                                                                <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                                    <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                                    {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                                    {story_type === 'group' && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={group_task_type !== null ? `Group Task  ${group_task_type} Access` : null}>{"GR"} {group_task_type !== null ? `${group_task_type?.substring(0, 1)}A` : null}</div>}
                                                                    <div>{blocking_id !== false ?
                                                                        blocking_id[0].us_id !== null &&
                                                                        <p style={{ fontWeight: 'bold', cursor: 'pointer', backgroundColor: 'red', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" >{getSubStringId(project_name, 3)}{'-'}{blocking_id[0].us_id}</p> :
                                                                        null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: '100%', alignItems: 'center', display: isMobile ? "none" : 'flex' }}>
                                                        {
                                                            status === 'completed' ?
                                                                <del>
                                                                    <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>

                                                                </del>
                                                                :
                                                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                        }
                                                        {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                        {story_type === 'group' && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={group_task_type !== null ? `Group Task  ${group_task_type} Access` : null}>{"GR"} {group_task_type !== null ? `${group_task_type?.substring(0, 1)}A` : null}</div>}
                                                        <div>{blocking_id !== false ?
                                                            blocking_id[0].us_id !== null &&
                                                            <p style={{ fontWeight: 'bold', cursor: 'pointer', backgroundColor: 'red', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" >{getSubStringId(project_name, 3)}{'-'}{blocking_id[0].us_id}</p> :
                                                            null}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ padding: '5px', marginRight: '5px', marginTop: '2px', float: "right", display: isMobile ? "none" : {} }}>
                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                {
                                                                    getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                }
                                                            </button>
                                                        </div>
                                                        <div style={{ padding: '5px', marginRight: '5px', float: "left", display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <button
                                                                style={{
                                                                    border: "0",
                                                                    width: "8px",
                                                                    height: "30px",
                                                                    backgroundColor: "transparent",
                                                                }}
                                                                type="button"
                                                                onClick={() =>
                                                                    handleOpen(
                                                                        // eslint-disable-next-line
                                                                        task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                                                        {
                                                                            title: todo.title,
                                                                            subTaskDesc: todo.subTaskDesc,
                                                                            us_id: todo.us_id,
                                                                            id: todo.id,
                                                                            main_task_id: todo.main_task_id,
                                                                            dependencyId: todo.dependencyId,
                                                                            timeLeft: todo.timeLeft,
                                                                            complete_status: todo.complete_status,
                                                                            modifiedby: todo.modifiedby,
                                                                            extraHours: todo.extraHours,
                                                                            mainTaskTitle: todo.mainTaskTitle,
                                                                            taskDesc: todo.taskDesc,
                                                                            epic_name: todo.epic_name,
                                                                            laneId: todo.laneId,
                                                                            moduleDesc: todo.moduleDesc,
                                                                            story_points: todo.story_points,
                                                                            acceptanceCriteria: todo.acceptanceCriteria,
                                                                            assignedDate: todo.assignedDate,
                                                                            targetDate: todo.targetDate,
                                                                            modifiedDate: todo.modifiedDate,
                                                                            action: task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                                                            task_end_date: todo.task_end_date,
                                                                            road_block_ids: todo.road_block_ids,
                                                                            combine_tasks: todo.combine_tasks,
                                                                            blocking_id: todo.blocking_id,
                                                                            from_device: todo.from_device
                                                                        })}
                                                            >
                                                                <img
                                                                    src="images/common/comments.svg"
                                                                    alt="logo"
                                                                    title={COMMENTS}
                                                                    style={{ width: "20px", height: "25px", marginLeft: "-10px", marginTop: "-9px" }}
                                                                />
                                                            </button>
                                                        </div>
                                                        <div style={{ backgroundColor: value === 0 ? '#000080' : '#019BE1', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title={value === 0 ? `Assigned By: ${assignedby}` : `Assigned To ${assignedto}`}>{getSubStringId(value === 0 ? assignedby : assignedto, 3)}</div>
                                                        {/* {loading === story_id ? <div style={{ backgroundColor: "grey", width: '90px', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, height: 'fit-content', textAlign: 'center' }}>Loading ... </div> :
                                                    <> */}
                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {
                                                            if (task_type === 'sub_task') {
                                                                getActiveSubUserStory(getUser.user, task_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, main_task_id, story_id, project_id, us_id, priority)

                                                            } else {
                                                                getActiveUserStory(activities, getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name)
                                                            }
                                                        }}>Start</div> : null}
                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {

                                                            if (task_type === 'sub_task') {
                                                                updateSetDone(activities, getUser.user, task_id, story_id, handleClose, `${SUBTASK} is completed`)
                                                            } else {
                                                                updateUserStory(activities, getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)
                                                            }
                                                        }}>Inprogress</div> : null}
                                                        {((sprintActiveStatus === PENDING_SUBTASKS || active_status === '-1') && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => {
                                                            if (task_type === 'sub_task') {
                                                                Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanbanSubTask(getUser.user, task_id, sprint_id, handleClose, handleOpen, `${SUBTASK} in progress`, project_name, main_task_id, story_id, project_id, us_id, priority, SPRINT_OR_KANBAN_WARNING))

                                                            } else {
                                                                Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanban(activities, getUser.user, story_id, MAINTASK, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, project_id, target_date, SPRINT_OR_KANBAN_WARNING))
                                                            }
                                                        }}>Start</div> : null}
                                                        {/* </>
                                                    } */}
                                                        {value === 1 || value === 2 ? getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId, 'Follow Up')
                                                            : <div style={{ backgroundColor: '#7290ce', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Delegate" onClick={() => Alert('warning', active_status === '-1' ? BlockedDelegate : handleOpen("delegate", todo))}>Delegate</div>}
                                                        {/* <div style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '155px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</div> */}
                                                        {/* Don`t remove these below lines which were related to Epic and Project Names and redirections` */}
                                                        {/* <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</div>
                                                <div style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</div> */}
                                                        <div style={{ backgroundColor: priorityColorCodes[priority_level] ? priorityColorCodes[priority_level] : '#4682B4', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '65px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{priority_level}</div>
                                                        {value === 1 || value === 2 ? isMobile ? <div style={{ width: '80px' }}></div> : null : null}
                                                        {/* <div style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{story_points}</div> */}
                                                        {
                                                            status !== 'completed' ?
                                                                <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right", display: isMobile ? "none" : {} }}>
                                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                    <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                                    </a>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                        <div>
                                                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                            {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                            {accessRole && epic_name === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                            {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                            {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                            {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                            {
                                                                                story_type === 'group' && main_task_id === '0' ?
                                                                                    blocked_id === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>
                                                                                    :
                                                                                    active_status !== '-1' && story_type !== 'group' && (accessRole || kanban_status === '1') && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => {
                                                                                        if (task_type === 'sub_task') {
                                                                                            handleOpen("modifySubTask", todo)
                                                                                        } else {
                                                                                            handleOpen("modifyTask", todo)
                                                                                        }
                                                                                    }}>{EDIT}</button>
                                                                            }
                                                                            {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                            {
                                                                                WORKFLOW_ENABLE === '1' ?
                                                                                    task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }} onClick={() => SquadChangeStatus(project_id, project_name, null, "null")}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}
                                                                            {
                                                                                WORKFLOW_ENABLE === '1' ?
                                                                                    task_type === 'main_task' && story_type !== 'group' ? <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button> : null
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : null}
                </tbody>
            </table >
        </>
    }

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <ul className="nav nav-pills nav-fill" style={{ padding: '10px', gap: '0.8rem' }}>
                            <li>
                                <span name='teamtasker' style={{ backgroundColor: switchTabs === 0 ? '#00008B' : '#FFFFFF', color: switchTabs === 0 ? '#FFFFFF' : '#00008B', padding: '5px 25px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleSwitchTabs(0)}>{CONSOLIDATED_TODO}</span>
                            </li>
                            <li>
                                <span name='personaltasker' style={{ backgroundColor: switchTabs === 1 ? '#00008B' : '#FFFFFF', color: switchTabs === 1 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleSwitchTabs(1)}>{PRIVATE_TODO}</span>
                            </li>
                            <li>
                                <span name='meetings' style={{ backgroundColor: switchTabs === 2 ? '#00008B' : '#FFFFFF', color: switchTabs === 2 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => { handleSwitchTabs(2); handleChange(0) }}>Meetings</span>
                            </li>
                        </ul>
                        {switchTabs === 0 && (
                            <>
                                <div className="justify-content-between row p-2" style={{ alignItems: 'center' }}>
                                    <div className="d-flex mt-1">
                                        <ul className="nav nav-pills nav-fill" style={{ padding: '10px', gap: '0.8rem' }}>
                                            <li>
                                                <span style={{ backgroundColor: value === 0 ? '#00008B' : '#FFFFFF', color: value === 0 ? '#FFFFFF' : '#00008B', padding: '5px 16px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(0)}>Assigned to me</span>
                                            </li>
                                            <li>
                                                <span style={{ backgroundColor: value === 1 ? '#00008B' : '#FFFFFF', color: value === 1 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => {
                                                    setSearchWord('')
                                                    handleChange(1)
                                                }}>Assigned to others</span>
                                            </li>
                                            <li>
                                                <span style={{ backgroundColor: value === 2 ? '#00008B' : '#FFFFFF', color: value === 2 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(2)}>Completed {MAINTASK}s</span>
                                            </li>
                                            {/* <li>
                                                <span style={{ backgroundColor: value === 2 ? '#00008B' : '#FFFFFF', color: value === 2 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(2)}>All {MAINTASK}s</span>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="d-flex mt-1" >
                                        <div class="input-group-prepend">
                                            <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10, display: isMobile ? 'none' : {} }}>Search: </span>
                                        </div>
                                        {
                                            !isMobile ?
                                                value === 1 ?
                                                    <input type="search" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                        value={searchWord}
                                                        onChange={(event) => handleSearch(event.target.value)}
                                                        placeholder="Search with id or title"
                                                    />
                                                    :
                                                    <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                        value={searchWord}
                                                        onChange={(event) => setSearchWord(event.target.value)}
                                                    />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="justify-content-end row" style={{ marginRight: '32%', display: isMobile ? 'none' : {} }}>
                                        {
                                            (getUser.user.role === 'Limited Access Contributor' && getUser.user.roleCount === '1') ?
                                                null
                                                :
                                                < button style={{ backgroundColor: 'transparent', border: '0', marginBottom: '10%' }} type="button" onClick={() => handleOpen("addUserStory", 0, {})}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                        }
                                        {open.action === "addUserStory" ?
                                            <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                    </div>
                                </div>
                                {
                                    isMobile &&
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className="d-flex justify-content-end mb-3 mt-2 sgt">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                                </div>
                                                {
                                                    value === 1 ?
                                                        <input type="search" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                            value={searchWord}
                                                            onChange={(event) => handleSearch(event.target.value)}
                                                            placeholder="Search with id or title"
                                                        />
                                                        :
                                                        <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                            value={searchWord}
                                                            onChange={(event) => setSearchWord(event.target.value)}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <div className="justify-content-end row sre">
                                            <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("addUserStory", 0, {})}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                            {open.action === "addUserStory" ?
                                                <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                                /> : null
                                            }
                                        </div>
                                    </>
                                }
                                <div className="col-lg-12 grid-margin stretch-card">
                                    {/* <div className="card"> */}
                                    <div className="card-body" style={{ overflowX: "auto", minHeight: '300px', padding: isMobile ? '0px 41px' : '0px' }}>
                                        <TabPanel value={value} index={0}>

                                            {
                                                // state.isLoading ?
                                                //     <RootLoader />
                                                //     :
                                                renderData([...state.consolidatedToDoTo.filter(val => {
                                                    // eslint-disable-next-line
                                                    return val.main_task_id !== "0" && val.story_type === 'group' || val.main_task_id === null && val.story_type !== 'group'
                                                }), ...state.consolidatedSubToDoTo.filter(item => { return item.assigned_to === getUser.user.empId })].filter((val) => {
                                                    // return val
                                                    return ((val.calendar_id === null || val.calendar_id === undefined))
                                                }))
                                            }
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            {
                                                state.isLoading ?
                                                    <RootLoader />
                                                    :
                                                    // eslint-disable-next-line
                                                    renderData((searchWord) && (state.allTasks.length > 0) ? state.allTasks : [...state.consolidatedToDoBy, ...state.consolidatedSubToDoBy].filter((val) => {
                                                        // eslint-disable-next-line
                                                        if ((val.calendar_id === null || val.calendar_id === undefined) && (val.main_task_id === "0" && val.story_type === 'group' || val.main_task_id === null && val.story_type !== 'group')) {
                                                            return val
                                                        } else {
                                                            if (new Date(val.endtime) >= new Date()) {
                                                                return val
                                                            }
                                                        }
                                                    }))
                                            }
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            {
                                                state.isLoading ?
                                                    <RootLoader />
                                                    :
                                                    // eslint-disable-next-line
                                                    renderAllData([...state.consolidatedCompleted, ...state.consolidatedSubCompleted].filter((val) => {
                                                        // return val
                                                        if (val.calendar_id === null || val.calendar_id === undefined) {
                                                            return val
                                                        } else {
                                                            if (new Date(val.endtime) >= new Date()) {
                                                                return val
                                                            }
                                                        }
                                                    }))
                                            }
                                        </TabPanel>
                                        {/* <TabPanel value={value} index={2}>
                                            {
                                                state.isLoading ?
                                                    <RootLoader />
                                                    :
                                                    searchWord.length > 0 ?
                                                        renderAllData(state.allTasks)
                                                        :
                                                        null
                                            }
                                        </TabPanel> */}
                                        {
                                            (open.action === "taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "workflow_task" ? <ChatWorkflow open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "modifyTask" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "delegate" ? <DelegateTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                                <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                                : null}
                                        {
                                            open.action === "Delete" ? <MainTaskDelete open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "Verify" ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            (open.action === "add_to_epic" || open.action === "current_add_to_sprint") ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "movetobacklog" ? <RoadBlock open={open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null}
                                        {
                                            open.action === 'modifySubTask' ? <ModifySubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} /> :
                                                null
                                        }
                                        {
                                            open.status && (open.action === 'commentInfo' || open.action === 'taskCommentInfo') ?
                                                <TaskInfo
                                                    open={open}
                                                    handleClose={handleModalClose}
                                                    data={cardInfo}
                                                    handleModalClose={handleModalClose} /> : null
                                        }
                                        {completeOpen ? (
                                            <MyTaskerCompleteReason
                                                open={completeOpen}
                                                handleClose={handleClose}
                                                data={cardInfo}
                                                handleModalClose={handleModalClose}
                                            />
                                        ) : null}
                                    </div>
                                    {/* </div> */}
                                </div>
                            </>
                        )}
                        {switchTabs === 1 && (
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="justify-content-between row p-2">
                                            {/* <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 10 }}>{CONSOLIDATED_TODO}</h4> */}
                                            <div className="d-flex justify-content-end mb-3 mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                                </div>
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '35px' }}
                                                    value={searchWord}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                            </div>
                                            <div className="justify-content-end row" >
                                                <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("add")}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                                {
                                                    open.action === "add" ? <AddPrivateToDo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                                    /> : null
                                                }
                                            </div>
                                        </div>

                                        {/* eslint-disable-next-line */}
                                        {state.privateToDo.length > 0 ? state.privateToDo.filter((val) => {
                                            if (searchWord === "") {
                                                return val
                                            } else if (val.story_title.toLowerCase().includes(searchWord.toLowerCase()) || val.us_id.toLowerCase().includes(searchWord.toLowerCase())) {
                                                return val
                                            }
                                        }).map((todo, index) => {
                                            return (
                                                <div className="col-12">
                                                    <div className="card col-12 ">
                                                        <div className="" >
                                                            <div class="d-flex justify-content-between col-12">
                                                                <div class="mr-auto p-2" onClick={(event) => handleOpen("private_todo_taskInfo", todo)}>
                                                                    <span style={{ width: '500px', cursor: 'pointer', paddingTop: 10, textTransform: 'capitalize' }} title={MAINTASKNAME}>{todo.status === "pending" ? <p style={{ color: 'black' }}>PT- {todo.pt_id}-{todo.story_title}</p> : <del><p style={{ color: 'black' }}> PT- {todo.pt_id}-{todo.story_title}</p></del>} </span>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    {todo.activeStatus === '0' && todo.status === 'pending' && <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, maxWidth: isMobile ? '50px' : '', width: isMobile ? '' : '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => getActiveUserStory(activities, getUser.user, todo.id, todo.sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Start</div>}
                                                                    {todo.activeStatus === '1' && todo.status === 'pending' && <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, maxWidth: isMobile ? '50px' : '', width: isMobile ? '' : '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => updateUserStory(activities, getUser.user, todo.id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Complete</div>}
                                                                    <p style={{ backgroundColor: priorityColorCodes[todo.priority_level], cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, maxWidth: isMobile ? '30px' : '', width: isMobile ? '' : '50px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{todo.priority_level}</p>
                                                                    {/* <p style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, padding: '3px', borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{todo.story_points}</p> */}
                                                                    <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: isMobile ? '5px' : '15px', marginRight: isMobile ? '5px' : '15px', padding: '5px', marginTop: 5, float: "right" }}>
                                                                        {/* eslint-disable-next-line */}
                                                                        {todo.status === "pending" ? <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                                            <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                        </a> :
                                                                            <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                        }
                                                                        {todo.status === "pending" &&
                                                                            <div className="dropdown-menu mn" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                                <div>
                                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" name='addtoproject' style={{ backgroundColor: "#203B5A", color: 'white' }}
                                                                                        onClick={(event) => handleOpen("addToProject", index, todo)}
                                                                                    >Add to Project</button>
                                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" name='modify' style={{ backgroundColor: '#FFC100', color: 'white' }}
                                                                                        onClick={(event) => handleOpen("modify", index, todo)}
                                                                                    >Modify</button>
                                                                                    {/* {getUser.user.empId === todo.assigned_by ?
                                                                                    <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: "#76C54E", color: 'white' }}
                                                                                        onClick={(event) => handleOpen("complete", index, todo)}
                                                                                    >Complete</button>
                                                                                    : ""} */}
                                                                                    {getUser.user.empId === todo.assigned_by ?
                                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" name='delete' style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                                            onClick={(event) => handleOpen("delete", index, todo)}
                                                                                        >Delete</button>
                                                                                        : ""}
                                                                                </div>
                                                                            </div>
                                                                            // :
                                                                            // <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                            //     <div>
                                                                            //         {getUser.user.empId === todo.assigned_by ?
                                                                            //             <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                            //                 onClick={(event) => handleOpen("delete", index, todo)}
                                                                            //             >Delete</button>
                                                                            //             : ""}
                                                                            //     </div>
                                                                            // </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                            : null}
                                        {
                                            (open.action === "private_todo_taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {open.action === "addToProject" ?
                                            <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {(open.action === "delete" || open.action === "complete") ?
                                            <DeletePrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {open.action === "modify" ?
                                            <ModifyPrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                                <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                                : null}
                                    </div>
                                </div>
                            </div>
                        )}
                        {switchTabs === 2 && (
                            <>
                                <div className="justify-content-between row p-2" style={{ alignItems: 'center' }}>
                                    <div>
                                        < button style={{ backgroundColor: 'transparent', border: '0'}} type="button">
                                            <Link to="/calendarDash" >
                                                <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">Add event</span>
                                            </Link>
                                        </button>
                                    </div>
                                    <div className="d-flex mt-1" >
                                        <div class="input-group-prepend">
                                            <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10, display: isMobile ? 'none' : {} }}>Search: </span>
                                        </div>
                                        {
                                            !isMobile ?
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                    value={searchWord}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                {
                                    isMobile &&
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className="d-flex justify-content-end mb-3 mt-2 sgt">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                                </div>
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                    value={searchWord}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="col-lg-12 grid-margin stretch-card">
                                    {/* <div className="card"> */}
                                    <div className="card-body" style={{ overflowX: "auto", minHeight: '300px' }}>
                                        <div className="accordion" style={{ border: '0px' }} id="accordionExample">
                                            <div key={1}>
                                                <div id={`headingDay`} >
                                                    <button style={{ backgroundColor: 'transparent', textAlign: 'left', padding: '2px', border: '5px', width: '100%' }} type="button" data-toggle="collapse" data-target={`#collapseDay`} aria-expanded="true" aria-controls={`collapseDay`} onClick={() => handleToggle('Day')}>
                                                        <h5 style={{ color: "blue", fontSize: '18px', fontWeight: 'semibold' }}>Today ({todayMeetings.length}) <FaChevronDown style={{ color: 'blue', fontSize: '22px', transform: getRotation('Day'), transition: 'transform 0.3s ease' }} /></h5>
                                                    </button>
                                                </div>
                                                {openPanel==='Day'?<div id={`collapseDay`} className={`collapse ${openPanel === 'Day' ? 'show' : ''}`} aria-labelledby={`headingDay`} data-parent="#accordionExample" >
                                                    {renderData([...todayMeetings,...completedMeetings])}
                                                </div>:null}
                                            </div>
                                            <div className="mt-2" key={2}>
                                                <div id={`headingWeek`} >
                                                    <button style={{ backgroundColor: 'transparent', textAlign: 'left', padding: '2px', border: '5px', width: '100%' }} type="button" data-toggle="collapse" data-target={`#collapseWeek`} aria-expanded="true" aria-controls={`collapseWeek`} onClick={() => handleToggle('Week')}>
                                                        <h5 style={{ color: "blue", fontSize: '18px', fontWeight: 'semibold' }}>Current Week ({weekMeetings.length}) <FaChevronDown style={{ color: 'blue', fontSize: '22px', transform: getRotation('Week'), transition: 'transform 0.3s ease' }} /></h5>
                                                    </button>
                                                </div>
                                                {openPanel==='Week'?<div id={`collapseWeek`} className={`collapse ${openPanel === 'Week' ? 'show' : ''}`} aria-labelledby={`headingWeek`} data-parent="#accordionExample" >
                                                    {renderData(weekMeetings)}
                                                </div>:null}
                                            </div>
                                            <div className="mt-2" key={3}>
                                                <div id={`headingMonth`} >
                                                    <button style={{ backgroundColor: 'transparent', textAlign: 'left', padding: '2px', border: '5px', width: '100%' }} type="button" data-toggle="collapse" data-target={`#collapseMonth`} aria-expanded="true" aria-controls={`collapseMonth`} onClick={() => handleToggle('Month')}>
                                                        <h5 style={{ color: "blue", fontSize: '18px', fontWeight: 'semibold' }}>Current Month ({monthMeetings.length}) <FaChevronDown style={{ color: 'blue', fontSize: '22px', transform: getRotation('Month'), transition: 'transform 0.3s ease' }} /></h5>
                                                    </button>
                                                </div>
                                                {openPanel==='Month'?<div id={`collapseMonth`} className={`collapse ${openPanel === 'Month' ? 'show' : ''}`} aria-labelledby={`headingMonth`} data-parent="#accordionExample" >
                                                    {renderData(monthMeetings)}
                                                </div>:null}
                                            </div>
                                            <div className="mt-2" key={4}>
                                                <div id={`headingYear`} >
                                                    <button style={{ backgroundColor: 'transparent', textAlign: 'left', padding: '2px', border: '5px', width: '100%' }} type="button" data-toggle="collapse" data-target={`#collapseYear`} aria-expanded="true" aria-controls={`collapseYear`} onClick={() => handleToggle('Year')} >
                                                        <h5 style={{ color: "blue", fontSize: '18px', fontWeight: 'semibold' }}>Current Year ({yearMeetings.length}) <FaChevronDown style={{ color: 'blue', fontSize: '22px', transform: getRotation('Year'), transition: 'transform 0.3s ease' }} /></h5>
                                                    </button>
                                                </div>
                                                {openPanel==='Year'?<div id={`collapseYear`} className={`collapse ${openPanel === 'Year' ? 'show' : ''}`} aria-labelledby={`headingYear`} data-parent="#accordionExample" >
                                                    {renderData(yearMeetings)}
                                                </div>:null}
                                            </div>
                                            <div className="mt-2" key={5}>
                                                <div id={`headingNext`} >
                                                    <button style={{ backgroundColor: 'transparent', textAlign: 'left', padding: '2px', border: '5px', width: '100%' }} type="button" data-toggle="collapse" data-target={`#collapseNext`} aria-expanded="true" aria-controls={`collapseNext`} onClick={() => handleToggle('Next')}>
                                                        <h5 style={{ color: "blue", fontSize: '18px', fontWeight: 'semibold' }}>Next Year ({exceptYearMeetings.length}) <FaChevronDown style={{ color: 'blue', fontSize: '22px', transform: getRotation('Next'), transition: 'transform 0.3s ease' }} /></h5>
                                                    </button>
                                                </div>
                                                {openPanel==='Next'?<div id={`collapseNext`} className={`collapse ${openPanel === 'Next' ? 'show' : ''}`} aria-labelledby={`headingNext`} data-parent="#accordionExample" >
                                                    {renderData(exceptYearMeetings)}
                                                </div>:null}
                                            </div>
                                        </div>
                                        {
                                            open.action === "workflow_task" ? <ChatWorkflow open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                                <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                                : null}
                                        {
                                            (open.action === "add_to_epic" || open.action === "current_add_to_sprint") ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "movetobacklog" ? <RoadBlock open={open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null}
                                        {
                                            (open.action === "taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                            /> : null
                                        }
                                        {
                                            open.status && open.action === 'commentInfo' ?
                                                <TaskInfo
                                                    open={open}
                                                    handleClose={handleModalClose}
                                                    data={cardInfo}
                                                    handleModalClose={handleModalClose} /> : null
                                        }
                                        {completeOpen ? (
                                            <MyTaskerCompleteReason
                                                open={completeOpen}
                                                handleClose={handleClose}
                                                data={cardInfo}
                                                handleModalClose={handleModalClose}
                                            />
                                        ) : null}
                                    </div>
                                    {/* </div> */}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}